/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $close = $(".pushy__close");
          $close.on('click', function() {
            $('.site-overlay').click();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $toggle = $(".toggle-accordion");
        $toggle.on('mouseover', function() {
            $toggle.addClass('toggle-clicked');
        });
        $toggle.on('mouseout', function() {
            $toggle.removeClass('toggle-clicked');
        });
        $('.news__slider').slick({
          adaptiveHeight: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: false
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
              }
            },
            {
            breakpoint: 481,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
              }
            }
          ]
        });


        $('.aree__slider').slick({
          adaptiveHeight: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
        });


        var panelTriggers = document.getElementsByClassName('js-cd-panel-trigger');
      	if( panelTriggers.length > 0 ) {
      		for(var i = 0; i < panelTriggers.length; i++) {
      			(function(i){
      				var panelClass = 'js-cd-panel-'+panelTriggers[i].getAttribute('data-panel'),
      					panel = document.getElementsByClassName(panelClass)[0];
      				// open panel when clicking on trigger btn
      				panelTriggers[i].addEventListener('click', function(event){
      					event.preventDefault();
      					addClass(panel, 'cd-panel--is-visible');
      				});
      				//close panel when clicking on 'x' or outside the panel
      				panel.addEventListener('click', function(event){
      					if( hasClass(event.target, 'js-cd-close') || hasClass(event.target, panelClass)) {
      						event.preventDefault();
      						removeClass(panel, 'cd-panel--is-visible');
      					}
      				});
      			})(i);
      		}
      	}

      	//class manipulations - needed if classList is not supported
      	//https://jaketrent.com/post/addremove-classes-raw-javascript/
      	function hasClass(el, className) {
      	  	if (el.classList) return el.classList.contains(className);
      	  	else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
      	}

      	function addClass(el, className) {
      	 	if (el.classList) el.classList.add(className);
      	 	else if (!hasClass(el, className)) el.className += " " + className;
      	}

      	function removeClass(el, className) {
      	  	if (el.classList) el.classList.remove(className);
      	  	else if (hasClass(el, className)) {
      	    	var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      	    	el.className=el.className.replace(reg, ' ');
      	  	}
      	}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_contatti': {
      init: function() {
        console.log("contacts");
        // JavaScript to be fired on the about us page
        function new_map( $el ) {

          var styles = [{
            stylers: [
              { lightness: 50 }
            ]
          }];

          // Create a new StyledMapType object, passing it the array of styles,
          // as well as the name to be displayed on the map type control.
          var styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});
        	// var
        	var $markers = $el.find('.marker');


        	// vars
        	var args = {
        		zoom		: 16,
        		center		: new google.maps.LatLng(0, 0),
        		mapTypeId	: google.maps.MapTypeId.ROADMAP
        	};


        	// create map
        	var map = new google.maps.Map( $el[0], args);
          /*
          map.mapTypes.set('map_style', styledMap);
          map.setMapTypeId('map_style');
          */
        	// add a markers reference
        	map.markers = [];


        	// add markers
        	$markers.each(function(){

            	add_marker( $(this), map );

        	});


        	// center map
        	center_map( map );


        	// return
        	return map;

        }

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	$marker (jQuery element)
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function add_marker( $marker, map ) {

        	// var
        	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        	// create marker
        	var marker = new google.maps.Marker({
        		position	: latlng,
        		map			: map
        	});

        	// add to array
        	map.markers.push( marker );

        	// if marker contains HTML, add it to an infoWindow
        	if( $marker.html() )
        	{
        		// create info window
        		var infowindow = new google.maps.InfoWindow({
        			content		: $marker.html()
        		});

        		// show info window when marker is clicked
        		google.maps.event.addListener(marker, 'click', function() {

        			infowindow.open( map, marker );

        		});
        	}

        }

        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function center_map( map ) {

        	// vars
        	var bounds = new google.maps.LatLngBounds();

        	// loop through all markers and create bounds
        	$.each( map.markers, function( i, marker ){

        		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        		bounds.extend( latlng );

        	});

        	// only 1 marker?
        	if( map.markers.length == 1 )
        	{
        		// set center of map
        	    map.setCenter( bounds.getCenter() );
        	    map.setZoom( 16 );
        	}
        	else
        	{
        		// fit to bounds
        		map.fitBounds( bounds );
        	}

        }

        /*
        *  document ready
        *
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	5.0.0
        *
        *  @param	n/a
        *  @return	n/a
        */
        // global var
        var map = null;

        $(document).ready(function(){

        	$('.acf-map').each(function(){

        		// create map
        		map = new_map( $(this) );

        	});

        });
        var panelTriggers = document.getElementsByClassName('js-cd-panel-trigger');
      	if( panelTriggers.length > 0 ) {
      		for(var i = 0; i < panelTriggers.length; i++) {
      			(function(i){
      				var panelClass = 'js-cd-panel-'+panelTriggers[i].getAttribute('data-panel'),
      					panel = document.getElementsByClassName(panelClass)[0];
      				// open panel when clicking on trigger btn
      				panelTriggers[i].addEventListener('click', function(event){
      					event.preventDefault();
      					addClass(panel, 'cd-panel--is-visible');
      				});
      				//close panel when clicking on 'x' or outside the panel
      				panel.addEventListener('click', function(event){
      					if( hasClass(event.target, 'js-cd-close') || hasClass(event.target, panelClass)) {
      						event.preventDefault();
      						removeClass(panel, 'cd-panel--is-visible');
      					}
      				});
      			})(i);
      		}
      	}

      	//class manipulations - needed if classList is not supported
      	//https://jaketrent.com/post/addremove-classes-raw-javascript/
      	function hasClass(el, className) {
      	  	if (el.classList) return el.classList.contains(className);
      	  	else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
      	}
      	function addClass(el, className) {
      	 	if (el.classList) el.classList.add(className);
      	 	else if (!hasClass(el, className)) el.className += " " + className;
      	}
      	function removeClass(el, className) {
      	  	if (el.classList) el.classList.remove(className);
      	  	else if (hasClass(el, className)) {
      	    	var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      	    	el.className=el.className.replace(reg, ' ');
      	  	}
      	}
      }
    },
    'single': {
      init: function() {

        $('.related-post__slider').slick({
          adaptiveHeight: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: false
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
              }
            },
            {
            breakpoint: 481,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
              }
            }
          ]
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
